<template>
  <div class="admin-sidebar">
    <nuxt-link
      v-for="(item, index) in sidebarLinkList"
      :key="index"
      :to="item.link"
      class="admin-sidebar__link"
      :class="{ 'admin-sidebar__link--active': route.path === item.link }"
    >
      <v-icon>mdi-{{ item.icon }}</v-icon>
      <span>{{ item.name }}</span>
    </nuxt-link>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from "vue-router";

const route = useRoute();

const sidebarLinkList = computed(() => {
  return [
    {
      name: "Дашборд",
      link: "/admin/",
      icon: "view-dashboard-outline",
    },
    {
      name: "CRM Система",
      link: "/admin/system",
      icon: "poll",
    },
    {
      name: "Фінансовий звіт для керівників",
      link: "/admin/finance",
      icon: "poll",
    },
    {
      name: "Заявки",
      link: "/admin/requests",
      icon: "clipboard-text",
    },
    {
      name: "Продавці",
      link: "/admin/sellers",
      icon: "clipboard-account-outline",
    },
    {
      name: "Покупці",
      link: "/admin/customers",
      icon: "clipboard-account-outline",
    },
    {
      name: "Відгуки",
      link: "/admin/reviews",
      icon: "message-draw",
    },
    {
      name: "Марки/моделі авто",
      link: "/admin/cars",
      icon: "car",
    },
    {
      name: "Рубрикатор запчастин",
      link: "/admin/parts",
      icon: "shape-plus-outline",
    },
    {
      name: "Географія міст",
      link: "/admin/cities",
      icon: "map-marker-outline",
    },
    {
      name: "Знижки/Акції",
      link: "/admin/discounts",
      icon: "tag",
    },
    {
      name: "Блокування",
      link: "/admin/ban",
      icon: "cancel",
    },
    {
      name: "Статистика",
      link: "/admin/statistics",
      icon: "chart-timeline-variant-shimmer",
    },
  ];
});
</script>

<style scoped lang="scss">
.admin {
  &-sidebar {
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: column;
    background: var(--color-main-white);
    overflow: auto;
  }

  &-sidebar__link {
    display: flex;
    align-items: center;
    gap: 32px;
    padding: 0 16px;
    min-height: 56px;

    color: var(--color-main-dark);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;

    transition: 0.3s;

    span {
      flex-wrap: wrap;
      max-width: 200px;
      transition: 0.3s;
    }

    &:hover,
    &--active {
      background: #e3effa;

      span {
        color: #1976d2;
      }
    }
  }
}
</style>
