<template>
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32 16.5C32 25.3366 24.8366 32.5 16 32.5C7.16344 32.5 0 25.3366 0 16.5C0 7.66344 7.16344 0.5 16 0.5C24.8366 0.5 32 7.66344 32 16.5Z"
      fill="#0D0213"
    />
    <path
      d="M4.15009 19.108H6.26209V20.5H3.21409C2.68609 20.004 2.28609 19.376 2.01409 18.616C1.74209 17.856 1.60609 17.076 1.60609 16.276C1.60609 15.468 1.75809 14.692 2.06209 13.948C2.36609 13.204 2.81809 12.588 3.41809 12.1H6.26209V13.54H4.15009C3.93409 13.788 3.74609 14.188 3.58609 14.74C3.43409 15.292 3.35809 15.82 3.35809 16.324C3.35809 16.828 3.43409 17.356 3.58609 17.908C3.74609 18.46 3.93409 18.86 4.15009 19.108Z"
      fill="white"
    />
    <path
      d="M9.85722 17.728H8.57322C8.40522 17.936 8.32122 18.196 8.32122 18.508C8.32122 18.82 8.40922 19.064 8.58522 19.24H9.85722V17.728ZM7.27722 14.368H10.2532C10.7332 14.656 11.0492 14.988 11.2012 15.364C11.3532 15.732 11.4292 16.236 11.4292 16.876V18.676C11.4292 19.34 11.4452 19.948 11.4772 20.5H10.1092L9.89322 20.068C9.58122 20.356 9.14122 20.5 8.57322 20.5H7.81722C7.13722 20.052 6.79722 19.408 6.79722 18.568C6.79722 17.72 7.14522 17.052 7.84122 16.564H8.96922C9.28122 16.564 9.57322 16.66 9.84522 16.852V16.252C9.84522 15.94 9.70922 15.732 9.43722 15.628H7.27722V14.368Z"
      fill="white"
    />
    <path
      d="M12.4733 20.5V14.368H13.6133L13.9133 15.016C14.2333 14.584 14.6373 14.368 15.1253 14.368H15.5933V15.976H14.0693V20.5H12.4733Z"
      fill="white"
    />
    <path
      d="M17.9616 13.54V16.54H19.0296C19.4696 16.18 19.6896 15.652 19.6896 14.956C19.6896 14.66 19.6296 14.38 19.5096 14.116C19.3976 13.852 19.2616 13.66 19.1016 13.54H17.9616ZM16.3416 20.5V12.1H19.5936C20.1376 12.34 20.5656 12.708 20.8776 13.204C21.1976 13.7 21.3576 14.28 21.3576 14.944C21.3576 15.6 21.1936 16.208 20.8656 16.768C20.5376 17.328 20.0536 17.724 19.4136 17.956H17.9616V20.5H16.3416Z"
      fill="white"
    />
    <path
      d="M25.5898 20.5H22.9738C22.1738 19.756 21.7738 18.74 21.7738 17.452C21.7738 16.804 21.8618 16.24 22.0378 15.76C22.2218 15.28 22.5098 14.816 22.9018 14.368H25.7098C26.1258 14.848 26.4098 15.316 26.5618 15.772C26.7138 16.22 26.7898 16.78 26.7898 17.452C26.7898 18.74 26.3898 19.756 25.5898 20.5ZM23.8258 19.24H24.7378C25.0258 18.68 25.1698 18.044 25.1698 17.332C25.1698 16.62 25.0218 16.052 24.7258 15.628H23.8258C23.5378 16.036 23.3938 16.616 23.3938 17.368C23.3938 18.112 23.5378 18.736 23.8258 19.24Z"
      fill="white"
    />
    <path
      d="M27.8916 17.824V15.628H27.0636V14.368H27.8916V12.7H29.4876V14.368H30.4716V15.628H29.4876V18.208C29.4876 18.696 29.5716 19.04 29.7396 19.24H30.4716V20.5H28.7676C28.3436 20.108 28.0876 19.684 27.9996 19.228C27.9276 18.844 27.8916 18.376 27.8916 17.824Z"
      fill="white"
    />
  </svg>
</template>
